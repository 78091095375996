import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import $ from "jquery";
import { navigate } from "@reach/router"

/**
 * Component
 */

import { SmallSearch } from '@components/icon/icon';

/**
 * Assets
 */

import './styles/_index.scss';

const StaticSearch = () => {
    useEffect(() => {
        let url = "/";

        $(".sales_btn").click(function() {
            var searcValue = $(".search_field_text").val();
            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = "in-london/"
            }
            navigate("/property/for-sale/"+url);
        })
    },[]);
    return (
        <Formik
            initialValues={{ search: '' }}
            validate={values => {
                const errors = {};
                if (!values.search) {
                errors.search = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className="static-search">
                    <Row>
                        <Col md="8" lg="8">
                            <Form.Group>
                                <div className="icon"><SmallSearch /></div>
                                <Form.Control className="search_field_text" type="text" placeholder="Street, area or postcode" name="search" onChange={handleChange} onBlur={handleBlur} value={values.search} />
                            </Form.Group>
                        </Col>
                        <Col md="4" lg="4">
                            <button type="submit" className="btn sales_btn" disabled={isSubmitting}>Search</button>
                        </Col>
                    </Row>
                </form>
            )}
        </Formik>
    )
}

export default StaticSearch
